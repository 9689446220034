.landing-page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background: linear-gradient(135deg, #f5f7fa, #c3cfe2);
  text-align: center;
  padding: 20px;
}

.title {
  font-size: 3rem;
  color: #333;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.5rem;
  color: #555;
  margin-bottom: 40px;
}

.coming-soon {
  font-size: 1.2rem;
  color: #777;
  margin-bottom: 20px;
}

.learn-more-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.learn-more-button:hover {
  background-color: #0056b3;
}

.description {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 20px;
  max-width: 600px;
  line-height: 1.5;
}
